<!--
 * @Description: 品质管理-计划报告
 * @Author: 马俊
 * @Date: 2021-04-27 11:16:21
 * @LastEditors: 马俊
 * @LastEditTime:
 -->
<template>
  <div class="qualityPlanReportList-wrapper">
    <list
      ref="list"
      :exportPermission="'export'"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :exportMethod="exportMethod"
      :searchParams="searchParams"
      :isMultiSelect="false"
      :headers="headers"
    >
      <!-- <template #headerSlot>
        <v-button text="新增" permission="add" @click="create"></v-button>
      </template> -->

      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.planName" />
        <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
        <!-- <v-select label="检查负责人" v-model="searchParams.responsibleUserId" :options="responsibleUserOps"/> -->
        <v-input label="检查负责人" v-model="searchParams.responsibleUserName" />
        <v-datepicker
          type="rangedatetimer"
          label="计划开始时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planStartTimeS"
          :endTime.sync="searchParams.planStartTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="计划完成时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.planEndTimeS"
          :endTime.sync="searchParams.planEndTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="检查开始时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.firstExecuteTimeS"
          :endTime.sync="searchParams.firstExecuteTimeE"
        />
        <v-datepicker
          type="rangedatetimer"
          label="检查完成时间"
          format="YYYY-MM-DD HH:mm"
          :startTime.sync="searchParams.lastExecuteTimeS"
          :endTime.sync="searchParams.lastExecuteTimeE"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看任务报告" type="text" permission="view" @click="view(scope.row)" />
      </template>
    </list>
  </div>
</template>

<script>
import {
  getQualityCheckPlanReportListURL,
  exportQualityCheckPlanReportList
} from './api'
import {
  statusMap,
  statusOps,
  scopeTypeMap,
  scopeTypeOps,
  checkScoreMap,
  checkScoreOps
} from './map'
import { communityParams } from 'common/select2Params'

export default {
  name: 'qualityPlanReportList',
  data () {
    return {
      searchUrl: getQualityCheckPlanReportListURL,
      exportUrl: exportQualityCheckPlanReportList,
      exportMethod: 'get',
      statusOps: statusOps(1),
      statusMap,
      scopeTypeOps: scopeTypeOps(1),
      scopeTypeMap,
      checkScoreOps: checkScoreOps(1),
      checkScoreMap,
      responsibleUserOps: [],
      communityParams,
      searchParams: {
        planName: '',
        communityId: '',
        responsibleUserName: '',
        planStartTimeS: '',
        planStartTimeE: '',
        planEndTimeS: '',
        planEndTimeE: '',
        firstExecuteTimeS: '',
        firstExecuteTimeE: '',
        lastExecuteTimeS: '',
        lastExecuteTimeE: ''
      },
      headers: [
        {
          prop: 'scopeType',
          label: '计划类型',
          formatter (row, props) {
            return scopeTypeMap[row.scopeType]
          }
        },
        {
          prop: 'planName',
          label: '计划名称'
        },
        {
          prop: 'communityName',
          label: '检查对象'
        },
        {
          prop: 'planStartTime',
          label: '计划起止时间',
          formatter (row) {
            return `${row.planStartTime}~${row.planEndTime}`
          }
        },
        {
          prop: 'firstExecuteTime',
          label: '首次检查时间'
        },
        {
          prop: 'lastExecuteTime',
          label: '检查结束时间'
        },
        {
          prop: 'responsibleUserName',
          label: '计划责任人'
        },
        {
          prop: 'status',
          label: '计划状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'checkScore',
          label: '检查总分',
          formatter (row) {
            // displayModel 1 按评分 2 按是否合格
            if (row.displayModel === 1) {
              return row.checkScore
            }
            return checkScoreMap[row.checkScore]
          }
        }
      ]
    }
  },
  methods: {
    view (row) {
      this.$router.push({
        name: 'qualityTaskReportList',
        query: {
          // planId: row.planId,
          communityId: row.communityId,
          planName: row.planName
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
