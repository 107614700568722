/** 检查表模板 */
// 查询计划报告列表
const getQualityCheckPlanReportListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckPlanReportList`
// 查询计划报告列表
const exportQualityCheckPlanReportList = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckPlanReportList`
// 查询任务报告列表
const getQualityCheckTaskReportListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportList`
// 查询任务报告列表
const exportQualityCheckTaskReportList = `${API_CONFIG.butlerBaseURL}qualityManagement/exportQualityCheckTaskReportList`

export {
  getQualityCheckPlanReportListURL,
  exportQualityCheckPlanReportList,
  getQualityCheckTaskReportListURL,
  exportQualityCheckTaskReportList
}
